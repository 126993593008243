import { FreightType, UserPort } from "../common/common";
import { CostByCategory } from "./common";

/** 환적 여부 */
enum TransitTypeOption {
  Ts = "t/s",
  Direct = "direct",
  All = "all",
}

/** 화물 운반 타입 */
enum FreightTypeOption {
  FCL = "FCL",
  LCL = "LCL",
  Air = "AIR",
}

/** 정렬 옵션 */
enum SortOption {
  ETD = "etd",
  ETA = "eta",
  TransitTimeInDays = "transitTimeInDays",
  Cost = "cost",
}

enum OrderOption {
  Asc = "ASC",
  Desc = "DESC",
}

type ScheduleForList = {
  /** 스케줄 ID */
  id: number;
  /** 선사/항공사 로고 이미지 URL */
  logoUrl?: string;
  /** 운송사 이름 (화면 표기법), example: 'KMTC' */
  carrier: string;
  /** 모선명(POR인 경우 barge, truck, rail), example: 'SKY VICTORIA */
  shipName: string;
  /** 항차(AIR인 경우 편명), example: '2332E */
  voyagerNo: string;
  /** 출발 국가코드 + 선적항, example: 'CNSHK */
  pol: string;
  /** 출발항 ID */
  polId: number;
  /** 출발항 이름, example: 'SHEKOU */
  polPortName: string;
  /** 출발항 한글 이름, example: '서커우항' */
  polPortKrName: string;
  /** 도착 국가코드 + 하역항, example: 'KRPUS */
  pod: string;
  /** 도착항 ID */
  podId: number;
  /** 도착항 이름, example: 'BUSAN' */
  podPortName: string;
  /** 도착항 한글 이름, example: '부산항' */
  podPortKrName: string;
  /** 소요일 */
  transitTimeInDays: number;
  /** 환적 여부 (true: 환적 / false: 직항)  */
  ts: boolean;
  /** 1차 환적 선명 */
  tsShipName1?: string;
  /** 1차 환적 항차 */
  tsVoyagerNo1?: string;
  /** 2차 환적 선명 */
  tsShipName2?: string;
  /** 2차 환적 항차 */
  tsVoyagerNo2?: string;

  /** 최종 가격 (할인이 적용된 금액) */
  cost?: number;
  /** 원가 (할인이 적용되지 않은 금액) */
  originalPrice?: number;
  /** 첫 운송 할인 금액 */
  firstDiscountPrice?: number;

  /** 출발예정일 */
  fullETD: string;
  /** 도착예정일 */
  fullETA: string;
  /**
   * 운임 응답 상태값
   *   - 비회원인 경우 invalidUser가 기본값이며,
   *   - 단, scheduleIdListForQuote로 전달한 스케줄의 경우 운임 정보가 있으면 success, 없으면 noValue로 변경됨
   */
  quoteResponseState: QuoteResponseState;

  /** 서류 마감일 */
  docCloseTime?: string;
  /** 화물 마감일 */
  cargoCloseTime?: string;
};

type FilterSummary = {
  /** 소요일 */
  transitDay: {
    minTransitDay: number;
    maxTransitDay: number;
  };
  /** 출발지 */
  pol: { id: number; name: string }[];
  /** 도착지 */
  pod: { id: number; name: string }[];
  /** 운송사 */
  carrier: { id: number; name: string }[];
};

type HeroSectionSchedule = {
  /** 화물타입 */
  freightType: FreightTypeOption;
  /** 스케줄 ID */
  scheduleId: number;
  /** 출발지 한글명 */
  polPortKrName?: string;
  /** 출발 국가 코드 */
  polCountry: string;
  /** 출발지 코드 */
  pol: string;
  /** 출발지 ID */
  polId: number;
  /** 도착지 한글명 */
  podPortKrName?: string;
  /** 도착 국가 코드 */
  podCountry: string;
  /** 도착지 코드 */
  pod: string;
  /** 도착지 ID */
  podId: number;
  /** 선사 로고 이미지 URL */
  logoUrl?: string;
  /** 선사 */
  carrier?: string;
  /** ETD */
  etd: string;
  /** ETA */
  eta: string;
  /** 최종 가격 (할인 금액이 적용된 금액) */
  cost?: number;
  /** 원가 (할인이 적용되지 않은 금액) */
  originalPrice?: number;
  /** 첫 운송 할인 적용 시 할인되는 금액 */
  firstDiscountPrice?: number;
  /** 출발지 영문명 */
  polPortEnName: string;
  /** 도착지 영문명 */
  podPortEnName: string;
  /** 창고id - 창고출발의 스케줄(ex. 위해항 출발)인 경우만 있다 */
  warehouseId?: number;
  /** 창고명 */
  warehouseName?: string;
};

type ScheduleForDetail = {
  /** 스케줄 ID */
  id: number;
  /** 운송사 이름 (화면 표기법), example: 'KMTC' */
  carrier: string;
  /** 모선명(POR인 경우 barge, truck, rail), example: 'SKY VICTORIA */
  shipName: string;
  /** 항차(AIR인 경우 편명), example: '2332E */
  voyagerNo: string;
  /** 출발예정일 */
  etd: string;
  /** 도착예정일 */
  eta: string;
  /** 소요일 */
  transitTimeInDays: number;
  /** 환적 여부 (true: 환적 / false: 직항)  */
  ts: boolean;
  /** 서류 마감일 */
  docCloseTime?: string;
  /** 화물 마감일 */
  cargoCloseTime?: string;
  /** 선사/항공사 로고 이미지 URL */
  logoUrl?: string;
  /** 상세 운임 */
  quote?: {
    /** 최종 가격 (할인이 적용된 금액) */
    cost: number;
    /** 원가 (할인이 적용되지 않은 금액) */
    originalPrice: number;
    /** 첫 운송 할인 금액 */
    firstDiscountPrice: number;
    /** 상세 운임 정보 */
    costByCategory: CostByCategory;
  };
  /** 환적지 정보 */
  transferShipment?: TransferShipment[];
  /** 출발 항구 정보 */
  startPort: Port;
  /** 도착 항구 정보 */
  endPort: Port;
  /** 스케줄이 유효한지 여부 (etd, eta가 지났거나 삭제되었는지) */
  isValidSchedule: boolean;
  /** 운임 응답 상태값 */
  quoteResponseState: QuoteResponseState;
  /** 화물타입 */
  freightType: FreightType;
};

type Fee = {
  key: string;
  /** 상세 운임 항목명 */
  item: string;
  atCost: boolean;
  note: string;
  isVAT: boolean;
  itemUnitMeasurement: string;
  isTaxExempt: boolean;
  amount: number;
  currency: string;
  /** 상세 운임 항목 금액 */
  itemPrice: number;
  unitPrice: number;
};

type TransferShipment = {
  id: number;
  code: string;
  name: string;
  nameEN: string;
  shipName: string;
  voyagerNo: string;
};

type Port = {
  id: number;
  code: string;
  name: string;
  nameEN: string;
  /** 위도 */
  lat: number;
  /** 경도 */
  lng: number;
};

/** 운임 응답 상태값 (success: 정상 응답, invalidUser: 운임 정보에 접근할 수 없는 사용자, noValue: 운임이 없음) */
type QuoteResponseState = "success" | "invalidUser" | "noValue";

interface RecommendLine {
  recommendedPol: UserPort;
  recommendedPod: UserPort;
}

export type {
  ScheduleForList,
  FilterSummary,
  HeroSectionSchedule,
  ScheduleForDetail,
  QuoteResponseState,
  Fee,
  RecommendLine,
};

export { TransitTypeOption, FreightTypeOption, SortOption, OrderOption };
